import { ScrollbarPlugin } from 'smooth-scrollbar';

export default class ModalPlugin extends ScrollbarPlugin {
	static pluginName = 'stopScroll';

	static defaultOptions = {
		stop: true,
	};

	transformDelta(delta: any) {
		return this.options.stop ? { x: 0, y: 0 } : delta;
	}
}
