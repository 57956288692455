import { useEffect } from "react";
import "@/styles/globals.scss";
import type { AppProps } from "next/app";
import Layout from "@/layout/Layout";
import { Router } from 'next/router';
import { GoogleAnalytics } from "nextjs-google-analytics";
import { GTMscript } from '@/components/GTM';
import { isMobile, isTablet } from "react-device-detect";
import {mounted} from "@/utils/scrollbar";

export default function App({ Component, pageProps }: AppProps) {
  const device = {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet
}
  Router.events.on('routeChangeStart', () => {
    // No realizamos ningún desplazamiento aquíwindow
    window.scrollTo({
      behavior:'smooth'
    })
  });
  useEffect( () => {
    // router.events.on('routeChangeStart', (router) => setChange(router))
    // setTimeout(()=>{
      // console.log(device)
      // mounted(device);
      
    // },1000)
  },[])
  
  return(
  // <AnimatePresence mode="wait" initial={false}>
    <Layout>
      <GoogleAnalytics trackPageViews />
      <Component {...pageProps}  />
      <GTMscript />
    </Layout>
  // </AnimatePresence>
  )
}
