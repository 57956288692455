
import Scrollbar from "smooth-scrollbar";
import DisableScrollPlugin from "./scrollbarPlugins/DisableScrollPlugin";
import StopScrollPlugin from "./scrollbarPlugins/StopScrollPlugin";
import EdgeEasingPlugin from "./scrollbarPlugins/EdgeEasingPlugin";
// import  useInvitation  from "../store/Context";
// import { setAnimations } from "./animations";
import { gsap } from "gsap";
import {ScrollTrigger}  from "gsap/dist/ScrollTrigger";
// let device ;

if (process.client) {

        // gsap.registerPlugin(ScrollToPlugin);
        gsap.registerPlugin(ScrollTrigger);
        // gsap.registerPlugin(SplitText);
        Scrollbar.use(DisableScrollPlugin);
        Scrollbar.use(StopScrollPlugin);
        Scrollbar.use(EdgeEasingPlugin);
}
// methods: {
    // # ***********************************************************************
    // # INIT SCROLLBAR
 // MOUNTEDDD 
// 
let scrollLife = null;
let YTrack = null;
let bodyContainer= null
let yControls= null;
let relife =  null;
//  const { device} = useInvitation()
export function stopScroll(flag, device){
  if(scrollLife){
  if(flag){
    scrollLife.updatePluginOptions("stopScroll", {
      stop: true
    })
  }else {
    
    scrollLife.updatePluginOptions("stopScroll", {
      stop: false
    })
    
  }
}

}
  export function mounted(  device, body){
    if (process.client) {

      // gsap.registerPlugin(ScrollToPlugin);
      gsap.registerPlugin(ScrollTrigger);
      // gsap.registerPlugin(SplitText);
      Scrollbar.use(DisableScrollPlugin);
      Scrollbar.use(StopScrollPlugin);
      Scrollbar.use(EdgeEasingPlugin);
}
    bodyContainer = body;
        window.scrollTo(0, 0);
        // scrollLife = body;
    if (device.isDesktop) body.classList.add("hasScrollbar");
    setTimeout(() => {
      // INIT SCROLLBARS
      initScrollbarBody(device, body);
      if (!device.isDesktop) {
        initScrollbars(device);
        console.log('estamos en desktop')
      }

      // RESIZE
      // if (device.isMobile) {
      //   let initialWidth = window.innerWidth;
      //   window.addEventListener("resize", onResize);
      //   console.log(initialWidth)
      // }

      // ANIMATIONS (mixins/animations.js)
      // setAnimations();

      // next("stop-scroll", false);
    // scrollbarBody.updatePluginOptions("stopScroll", {
    //         stop: true
    //       });
    //       trackY.element.style.pointerEvents = "none";
      ScrollTrigger.refresh();

      // isLoaded( true );
    }, 100);
      
    console.log(device)
   
  }

  function  initScrollbarBody(device, body) {
    // if (process.client) {

      // gsap.registerPlugin(ScrollToPlugin);
      gsap.registerPlugin(ScrollTrigger);
      // gsap.registerPlugin(SplitText);
      Scrollbar.use(DisableScrollPlugin);
      Scrollbar.use(StopScrollPlugin);
      Scrollbar.use(EdgeEasingPlugin);
// }
    // SMOOTH SCROLLBAR
  //   if (device) {
      const scrollbarBody = Scrollbar.init(body, {
        damping: 0.05,
        syncCallbacks: false,
        delegateTo: document,
        plugins: {
          disableScroll: { direction: "x" }
        }
      });
      
      scrollLife = scrollbarBody;
      scrollLife.setPosition(0, 0);
      scrollLife.updatePluginOptions("stopScroll", {
        stop: false
      })
      const trackX = scrollLife.track.xAxis;
      const trackY = scrollLife.track.yAxis;
      yControls = trackY;
      trackX.element.remove();
      const de = device.isDesktop;

      triggerProxy(scrollLife);
      scrollLife.addListener((e) => onScroll(e,de));
      console.log(scrollbarBody, scrollLife)
      // setAnchorLinks(scrollbarBody);

   
  }
  export function videoShow(video){
    video.style.top = `${YTrack + 75}px`
    // if(scrollLife){
      // if(flag){
        scrollLife.updatePluginOptions("stopScroll", {
          stop: true
        })
      // }else {
        
      //   scrollLife.updatePluginOptions("stopScroll", {
      //     stop: false
      //   })
        
      // }

  }

function onScroll(event, device) {
  console.log('device?',device)
  const y = event.offset.y;
  YTrack = y;
  setFixed(y, device);
}

function setFixed(y, device) {
  // if (device) {
    console.log("entramos al SetFixed")
    const fixed = document.querySelectorAll("[scrollbar-fixed]");
    fixed.forEach(element => {
      element.style.top = y + "px";
    });
  // }
}
function initScrollbars(device) {
  if (!device) return;

  const scrollbars = document.querySelectorAll("[scrollbar]");
  scrollbars?.forEach(scrollbar => {
    Scrollbar.init(scrollbar, {
      damping: 0.05,
      plugins: {
        disableScroll: { direction: "x" },
        stopScroll: { stop: false }
      }
    });
  });
}
function triggerProxy(scrollbarBody) {
  // if (process.client) {
      // gsap.registerPlugin(ScrollToPlugin);
      gsap.registerPlugin(ScrollTrigger);
      // gsap.registerPlugin(SplitText);
      Scrollbar.use(DisableScrollPlugin);
      Scrollbar.use(StopScrollPlugin);
      Scrollbar.use(EdgeEasingPlugin);
      ScrollTrigger.scrollerProxy(".body", {
          scrollTop(value) {
            if (arguments.length) {
              scrollbarBody.scrollTop = value;
            }
            return scrollbarBody.scrollTop;
          },
          getBoundingClientRect() {
            return {
              top: 0,
              left: 0,
              width: window.innerWidth,
              height: window.innerHeight
            };
          },
          pinType: "transform"
        });
        scrollbarBody.addListener(ScrollTrigger.update);         
    // }

// this.$nuxt.$on("scroll-to", section => {
//   if (section) {
//     gsap.to(window, {
//       duration: 1.5,
//       scrollTo: section,
//     })
//   }
// })

}