import styles from "./Logo.module.scss";
import { event } from "nextjs-google-analytics";
interface LogoProps {
  link: string,
  size?: string
}
const Icon: React.FC<LogoProps> = ({link, size}) => {
  const handleGoogle = () => {
    event(`btn-facebook`, {
      category:'CTA'
    });
    const win = window.open(link, '_blank');
    win?.focus();
  }
  return (
    <div onClick={handleGoogle} className={`${styles.logo_social} ${styles[ size? size : '']}`}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.logo}
      
    >
      <path d="M19.1429 10.0243C19.1429 4.64855 14.9189 0.285645 9.71434 0.285645C4.50977 0.285645 0.285767 4.64855 0.285767 10.0243C0.285767 14.7378 3.5292 18.6624 7.82862 19.5681V12.9459H5.94291V10.0243H7.82862V7.58962C7.82862 5.71006 9.30891 4.1811 11.1286 4.1811H13.4858V7.10269H11.6001C11.0815 7.10269 10.6572 7.54093 10.6572 8.07655V10.0243H13.4858V12.9459H10.6572V19.7142C15.4186 19.2273 19.1429 15.0786 19.1429 10.0243Z" />
    </svg>
    </div>
  );
};

export default Icon;
