import { useState, useEffect, useRef } from "react";
import styles from "./NavBar.module.scss";
// import Search from "../../assets/images/search.svg";
import FB from "@/components/Logo/FB";
import Insta from "@/components/Logo/Insta";
import Search from "@/components/Logo/Search";
import searchBar from "@/assets/images/search-nav.svg";
import hamburguer from "@/assets/images/hamburguer.svg";
import exit from "@/assets/images/exit.svg";
import exitw from "@/assets/images/exitw.svg";
import Image from "next/image";
import Accordeon from "../Accordeon";
import Link from "next/link";
import axios from "axios";
import useSWR from "swr";
import Logo from "@/components/Logo/index";
import Phone from "@/components/Logo/Phone";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { stopScroll } from "@/utils/scrollbar";
import { isMobile, isTablet } from "react-device-detect";
const childVariants = {
  initial: {
    y: -50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
      y: {
        duration: 1,
      },
      staggerChildren: 0.3,
    },
  },
};

const container = {
  initial: {
    y: "-100%",
  },
  animate: {
    y: 0,
    transition: {
      style: "spring",
      delay: 1.2,
      duration: 1,
      delayChildren: 1.2,
      staggerChildren: 0.3,
    },
  },
};
const fetcher = async (args: string) => {
  let list: any[] = [];
  try {
    const { data } = await axios.get(args);

    for (let key in data.data) {
      let subList: any[] = [];
      for (const subKey in data.data[key]) {
        const obj = {
          slug: subKey,
          title: data.data[key][subKey],
        };
        subList = [...subList, obj];
      }
      list = [...list, { slug: key, titles: subList }];
    }

    return list;
  } catch (e) {}
};

export default function NavBar() {
  const [mobile, setMobile] = useState(false);
  const [on, setOn] = useState(false);
  const [search, setSearch] = useState(false);
  const [menu, setMenu] = useState(false);
  const [searchVal, setVal] = useState("");
  const [filter, setFilt] = useState<any[]>([]);
  const { data: list, error } = useSWR(`${process.env.api}/menu`, fetcher);
  const router = useRouter();
  const input = useRef<any>(null);
  const device = {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
  };

  const handleSearch = async (e: any) => {
    let list: any[] = [];
    setVal(e.target.value);
    try {
      const { data } = await axios.post(`${process.env.api}/search`, {
        query: e.target.value,
      });
      console.log(data)
      for (const d in data.data) {
        list = [...list, { name: d, list: data.data[d] }];
      }
    list.map((item, i) => {
     if(item.list.length < 1) {
      setFilt([ {name: '', list: [{menu_title:'No hay resultados'}]}])
     }else {
      setFilt(list);
     }
    })
      
    } catch (e) {}
    if (e.target.value === "") {
      setFilt([]);
      setVal("");
    }
  };
  useEffect(() => {
    window.innerWidth < 1200 ? setMobile(true) : setMobile(false);
    window.addEventListener("resize", () => {
      window.innerWidth < 1200 ? setMobile(true) : setMobile(false);
    });
  }, []);
  useEffect(() => {
    on ? stopScroll(true, device) : stopScroll(false, device);
  }, [on]);
  const activeSearch = () => {
    setSearch(true);
    setOn(false);
    setFilt([]);
    if (input.current) input.current.focus();
  };
useEffect(() => {
  if(!search){
    setFilt([]);
      setVal("");
  }
},[search])
  return (
    <motion.nav
      className={styles.nav}
      variants={container}
      initial={"initial"}
      animate={"animate"}
    >
      <div className={`${styles.menu_bar} ${on ? styles.active : ""}`}>
        <Image
          src={exit}
          alt="salir"
          width={36}
          height={36}
          className={styles.searchbar_exit}
          onClick={() => {
            setOn(false);
          }}
        />
        <section>
          {list &&
            list.map((item: any, i: number) => (
              <div key={i} className={styles.menu_bar_section}>
                <h3>
                  {item.slug === "treatments"
                    ? "Tratamientos"
                    : item.slug === "zones"
                    ? "Zonas a tratar"
                    : "Preocupaciones"}
                </h3>
                {/* </Link> */}
                <ul>
                  {item.titles.map((sub: any, subi: number) => (
                    <Link
                      key={`${subi}-${i}`}
                      href={`/${
                        item.slug === "treatments"
                          ? "/tratamiento"
                          : item.slug === "zones"
                          ? "/zona"
                          : "/preocupacion"
                      }/${sub.slug.replace(" ", "-").toLowerCase()}`}
                      onClick={() => setOn(false)}
                    >
                      <li
                        className={
                          router.query.index === sub.slug
                            ? styles.active
                            : router.query.id === sub.slug
                            ? styles.active
                            : ""
                        }
                      >
                        {sub.title}
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            ))}
        </section>
      </div>
      <div
        className={`${styles.menu_searchbar_container} ${
          search ? styles.active : ""
        }`}
      >
        <Image
          src={exit}
          alt="salir"
          width={36}
          height={36}
          className={styles.searchbar_exit}
          onClick={() => {
            setFilt([]);
            setSearch(false);
          }}
        />
        <div className={styles.menu_searchbar}>
          <div className={styles.menu_searchbar_input}>
            <Image src={searchBar} alt="icon" width={24} height={24} />
            <input
              type={"text"}
              placeholder={"Buscar"}
              ref={input}
              value={search ? searchVal : ""}
              onChange={(e) => handleSearch(e)}
              onBlur={(e) => handleSearch(e)}
            />
          </div>
          <div className={styles.menu_searchbar_result}>
            {filter.map(
              (filt, i) =>
                filt.list.length > 0 && (
                  <>
                    <h4>
                      {filt.name === "treatments"
                        ? "Tratamientos"
                        : filt.name === "zones"
                        ? "Zonas a tratar"
                        : filt.name === "concerns"
                        ? 'Preocupaciones'
                        : ''}
                    </h4>
                    {filt.list.map((item: any, ix: number) => (
                      <Link
                        key={i + ix}
                        onClick={() => {
                          setFilt([]);
                          setSearch(false);
                        }}
                        href={`/${
                          filt.name === "treatments" 
                            ? "/tratamiento"
                            : filt.name === "zones"
                            ? "/zona"
                            : "/preocupacion"
                        }/${item.slug}`}
                      >
                        <p className={ router.query.index === item.slug
                            ? styles.active
                            : router.query.id === item.slug
                            ? styles.active
                            : ""}>{item.menu_title}</p>{" "}
                      </Link>
                    ))}
                  </>
                )
            )}
          </div>
        </div>
      </div>

      <div className={`${styles.menu_mobile} ${menu ? styles.active : ""}`}>
        <Image
          src={exitw}
          alt="salir"
          width={36}
          height={36}
          className={styles.exit}
          onClick={() => {
            setSearch(false);
            setMenu(!menu);
          }}
        />
        <div className={styles.menu_mobile_static}>
          <ul>
            <Link
              href={"/quienes-somos"}
              onClick={() => {
                setSearch(false);
                setMenu(!menu);
              }}
            >
              <li>Quiénes somos</li>
            </Link>
            <Link
              href="/tratamientos"
              onClick={() => {
                setSearch(false);
                setMenu(!menu);
              }}
            >
              {" "}
              <li>Tratamientos</li>
            </Link>
            <li className={styles.order_btn}>AGENDAR TURNO</li>
            <li className={styles.social_list}>
              <FB link="https://www.facebook.com/profile.php?id=61551652981975" />

              <Insta link="https://www.instagram.com/facehaus.ar/" />

              {/* <YT link="https://www.google.com/" /> */}
            </li>
          </ul>
        </div>
        <div className={styles.menu_mobile_accordeon}>
          <Accordeon
            list={list ? list : []}
            open={menu}
            action={() => setMenu(!menu)}
          />
        </div>
      </div>
      <Link
        href="/"
        className={styles.logo}
        onClick={() => {
          setOn(false);
          setSearch(false);
        }}
      >
        <motion.div variants={childVariants}>
          <Logo mobile={mobile} />
        </motion.div>
      </Link>

      {!mobile ? (
        <ul className={styles.nav_list}>
          <Link
            href={"/quienes-somos"}
            onClick={() => {
              setOn(false);
              setSearch(false);
            }}
          >
            <motion.li
              variants={childVariants}
              className={
                router.pathname === "/quienes-somos" ? styles.active : ""
              }
            >
              Quiénes somos
            </motion.li>
          </Link>
          <Link href={"/tratamientos"} onClick={() => setOn(false)}>
            <motion.li
              variants={childVariants}
              onMouseOver={() => {
                setOn(true);
                setSearch(false);
              }}
              className={
                router.query.tratamientos === "tratamientos"
                  ? styles.active
                  : ""
              }
            >
              Tratamientos
            </motion.li>
          </Link>
          <motion.li variants={childVariants} className={styles.order_btn}>
            <a href="https://facehaus.site.agendapro.com/ar" target="_blank">
              AGENDAR TURNO
            </a>
          </motion.li>
          <motion.li variants={childVariants}
            className={styles.social_list}>
              
            <a className={styles.social_list} href="tel: +5491152424919"> <Phone link="" />5242-4919</a>
          </motion.li>
          <motion.li
            variants={childVariants}
            className={styles.social_list}
            onClick={activeSearch}
          >
            <Search link="" />
            Buscar
          </motion.li>
          <motion.li variants={childVariants} className={styles.social_list}>
            <motion.div variants={childVariants}>
              <FB link="https://www.facebook.com/profile.php?id=61551652981975" />
            </motion.div>

            <motion.div variants={childVariants}>
              <Insta link="https://www.instagram.com/facehaus.ar/" />
            </motion.div>

            {/* <motion.div variants={childVariants}>
              <YT link="https://www.google.com/" />
            </motion.div> */}
          </motion.li>
        </ul>
      ) : (
        <ul className={styles.nav_list}>
          <motion.li variants={childVariants} className={styles.social_list} onClick={activeSearch}>
            {/* <Image
              className={styles.social_list_search}
              src={Search}
              width={36}
              height={36}
              alt="search"
              
            /> */}
            <Search link="" />
          </motion.li>
          <motion.li variants={childVariants} className={styles.social_list}>
            <Image
              className={styles.social_list_search}
              src={hamburguer}
              width={36}
              height={24}
              alt="menu"
              onClick={() => {
                setSearch(false);
                setMenu(true);
              }}
            />
          </motion.li>
        </ul>
      )}
    </motion.nav>
  );
}
