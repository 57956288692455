import styles from "./Logo.module.scss";
import { event } from "nextjs-google-analytics";
interface LogoProps {
  link: string;
  size?: string;
}

const Icon: React.FC<LogoProps> = ({ link, size }) => {
  const handleGoogle = () => {
    event(`btn-instagram`, {
      category: "CTA",
    });
  };
  return (
    <div
      onClick={handleGoogle}
      className={`logo_social ${styles.logo_social} ${styles[size ? size : ""]}`}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={`logo ${styles.logo}`}
        // fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 19C6.59 19 3 15.41 3 11C3 6.59 6.59 3 11 3C15.41 3 19 6.59 19 11C19 15.41 15.41 19 11 19ZM11 4C7.14 4 4 7.14 4 11C4 14.86 7.14 18 11 18C14.86 18 18 14.86 18 11C18 7.14 14.86 4 11 4Z"
          // fill="#45685A"
        />
        <path
          d="M21 21.5002C20.87 21.5002 20.74 21.4502 20.65 21.3502L16.65 17.3502C16.45 17.1502 16.45 16.8402 16.65 16.6402C16.85 16.4402 17.16 16.4402 17.36 16.6402L21.36 20.6402C21.56 20.8402 21.56 21.1502 21.36 21.3502C21.26 21.4502 21.13 21.5002 21 21.5002Z"
          // fill="#45685A"
        />
      </svg>
    </div>
  );
};

export default Icon;
