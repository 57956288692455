import { useEffect, useState, useRef } from "react";
import styles from "./Layout.module.scss";
import WPP from "@/assets/images/wpp.svg";
import phone from "@/assets/images/la_phone.png";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import FixedButton from "@/components/Button/FixedButton";
import { motion, AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import {mounted} from "@/utils/scrollbar";
import { deviceDetect, isMobile, isTablet } from "react-device-detect";


interface LayoutProps {
  children: React.ReactNode;
}
const childVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};
const container = {
  animate: {
    transition: {
      style: "spring",
      delayChildren: 2,
      staggerChildren: 0.3,
    },
  },
};
const pageVariant = {
  pageInitial: {
    opacity: 0,
  },
  pageAnimate: {
    opacity: 1,
    transition: {
      duration: 1.5,
      // delay: 0.1,
    },
  },
  pageExit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [hover, setHover] = useState<any>({ date: false, chat: false });
  const [showButton, setButton] = useState<boolean>(false)
  const router = useRouter();
  const containerRef = useRef<any>(null);
  const device = {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet
}
  useEffect( () => {
   isMobile ? setButton(true) : setButton(false);
  }, [containerRef])
  return (
    <div className={styles.layout} >
      <NavBar />
    {/* <div ref={containerRef} style={{ height: '100vh' }}> */}
      <AnimatePresence mode="wait">
        <motion.div
          
          key={router.asPath}
          initial="pageInitial"
          animate={"pageAnimate"}
          exit="pageExit"
          variants={pageVariant}
          
        >
          {children}
          
        </motion.div>
      </AnimatePresence>
      {/* <Footer /> */}
      {/* </div> */}
      <motion.div
        variants={container}
        initial="initial"
        animate="animate"
        className={styles.fixed_buttons}
      >
    { showButton && <motion.div
          variants={childVariants}
          onMouseOver={() => setHover({ ...hover, chat: true })}
          onMouseLeave={() => setHover({ ...hover, chat: false })}
        >
          <FixedButton
            ico={phone}
            type="dark"
            link="tel: +5491152424919"
            cta="agendá una consulta"
          />
           {/* <div className={`${styles.cta} ${hover.chat ? styles.active : ""}`}>
            <span>agendá una consulta</span>
          </div>  */}
        </motion.div> }
         {/* <motion.div
          variants={childVariants}
          onMouseOver={() => setHover({ ...hover, date: true })}
          onMouseLeave={() => setHover({ ...hover, date: false })}
        >
          <FixedButton ico={WPP} type="" link="https://wa.me/541560524919?text=Hola mi nombre es:" cta="chateá con nosotros" /> */}
          {/* <div
            className={`${styles.cta} ${styles.down} ${
              hover.date ? styles.active : ""
            }`}
          >
            <span>chateá con nosotros</span>
          </div>  */}
        {/* </motion.div>  */}
      </motion.div>
    </div>
  );
};

export default Layout;
