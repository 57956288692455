import Image from "next/image";
import styles from "./Fixed.module.scss";
import { event } from "nextjs-google-analytics";
import { useRouter } from "next/router";
interface ButtonProps {
  text?: string;
  ico: string | any;
  link?: string;
  type: string;
  cta: string;
}
const FixedButton: React.FC<ButtonProps> = ({ ico, text, link, type, cta }) => {
  const router = useRouter();
  const handleGoogle = () => {
    event("chat-bot", {
      category: "CTA",
    });
  };
  return (
    // <div>
    <a href={link} target="_blank" onClick={handleGoogle} >
    <div className={`${styles.fixed_container}  ${styles[type]}`}>
      <div className={`${styles.fixed_btn}`}>
        
        <figure>
          <Image src={ico} alt={text || ""} fill={true} />
        </figure>
      </div>

      <div className={styles.cta}><span>{cta}</span></div>
    </div>
</a>
    // </div>
  );
};
export default FixedButton;
