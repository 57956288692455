import { useState, useEffect } from "react";
import styles from "./Acordeon.module.scss";
import AccordeonItem from "./Item";
interface AccordeonProps {
  list: Array<any>;
  open: boolean;
  action: any;
}
const Accordeon: React.FC<AccordeonProps> = ({ list, open, action }) => {
  const [selected, setSelect] = useState<boolean |any >(null);
  function selection(i: number) {
    setSelect(i);
  }
  useEffect(() => {
    !open ? setSelect(false) : null;
  },[open])
  return (
    <ul className={styles.item_list}>
      {list.map((item, i) => (
        <li key={i}>
          <AccordeonItem
            title={item.slug}
            select={(selected === i) && (open === true)}
            list={item.titles}
            action={() =>selection(i)}
            open={open}
            touch={action}
          />
        </li>
      ))}
    </ul>
  );
};

export default Accordeon;
