import styles from "./Logo.module.scss";
import { event } from "nextjs-google-analytics";
interface LogoProps {
    link: string;
    size?: string;
}

const Icon: React.FC<LogoProps> = ({ link, size }) => {
    const handleGoogle = () => {
        event(`btn-menu-phone`, {
            category: "CTA",
        });
    };
    return (
        <div
            onClick={handleGoogle}
            className={`logo_social ${styles.logo_social} ${styles[size ? size : ""]}`}
        >
            <svg width="32" height="32" viewBox="0 0 32 32"  xmlns="http://www.w3.org/2000/svg">
                <path d="M8.65597 3C8.13297 3 7.61697 3.188 7.18697 3.531L7.12497 3.562L7.09397 3.594L3.96897 6.813L3.99997 6.843C3.54145 7.25935 3.21657 7.80223 3.06635 8.40309C2.91614 9.00395 2.94732 9.63585 3.15597 10.219C3.15997 10.227 3.15197 10.242 3.15597 10.25C4.00397 12.676 6.17197 17.36 10.406 21.594C14.656 25.844 19.402 27.926 21.75 28.844H21.781C22.3694 29.0385 22.9982 29.0774 23.6061 28.9568C24.214 28.8362 24.7803 28.5604 25.25 28.156L28.406 25C29.234 24.172 29.234 22.734 28.406 21.906L24.344 17.844L24.312 17.781C23.484 16.953 22.016 16.953 21.187 17.781L19.187 19.781C17.686 19.0599 16.3054 18.1114 15.094 16.969C13.457 15.406 12.621 13.609 12.313 12.906L14.313 10.906C15.153 10.066 15.168 8.668 14.281 7.844L14.312 7.812L14.219 7.719L10.219 3.594L10.188 3.563L10.125 3.531C9.70994 3.1921 9.19178 3.0048 8.65597 3ZM8.65597 5C8.73772 5.00475 8.81522 5.03801 8.87497 5.094L12.875 9.187L12.969 9.281C12.961 9.273 13.027 9.379 12.906 9.5L10.406 12L9.93698 12.438L10.157 13.062C10.157 13.062 11.305 16.137 13.719 18.438L13.938 18.625C16.261 20.746 19 21.906 19 21.906L19.625 22.188L22.594 19.218C22.766 19.047 22.734 19.047 22.906 19.218L27 23.314C27.172 23.485 27.172 23.423 27 23.594L23.937 26.657C23.477 27.052 22.988 27.134 22.407 26.939C20.141 26.048 15.738 24.114 11.812 20.189C7.85498 16.231 5.78897 11.743 5.03197 9.564C4.87897 9.157 4.98797 8.556 5.34397 8.251L5.40597 8.188L8.43797 5.095C8.49707 5.03907 8.5738 5.00548 8.65497 5H8.65597Z" />
            </svg>
        </div>
    );
};

export default Icon;
