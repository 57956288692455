import { useState, useEffect, useRef } from "react";
import styles from "./Item.module.scss";
import arrow from "@/assets/images/arrow-acc.svg";
import Image from "next/image";
import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";
interface ItemProps {
  title: string;
  list: Array<string>;
  open: boolean;
  touch: any;
  select: boolean;
  action: any;
}
const childVariants= {
  initial: {
    y: 15,
    opacity:0

  },
  animate: {
    y:0,
    opacity:1,
    transition: {
      duration: 0.3,
      ease: 'easeInOut'
    }
  },
  exit: {
    opacity:0,
    y: 15,
    transition: {
      duration: 0.5
    }
  }
};
const container = {
    initial: {
      height: "7rem",
    },
  animate: {
    height: 'auto',
    transition: {
      style: "spring",
      //   delay: 1.2,
      duration: 2,
      delayChildren: 0.1,
      staggerChildren: 0.5,
    },
  },
};
const AccordeonItem: React.FC<ItemProps> = ({
  title,
  list,
  open,
  touch,
  select,
  action,
}) => {
  const [openAcc, setAcc] = useState(false);
  const h = useRef<any>(null)
  useEffect(() => {
    !open ? setAcc(false) : setAcc(select);
    
  }, [open, select]);
  useEffect(()=> {
    openAcc ? container.animate.height = h.current.offsetHeight : null
  },[openAcc])
  function activateItem() {
    action();
    select ? setAcc(!openAcc) : setAcc(!select);
  }
  return (
    <motion.div ref={h} variants={container} className={styles.accordeon_item_wrapper}>
      <div className={styles.accordeon_header} onClick={() => activateItem()}>
        <h3>
          {title === "treatments"
            ? "Tratamientos"
            : title === "zones"
            ? "Zonas a tratar"
            : "Preocupaciones"}
        </h3>

        <Image
          src={arrow}
          alt="arrow"
          className={`${styles.arrow} ${
            openAcc && select ? styles.active : ""
          }`}
          width={36}
          height={36}
        />
      </div>
      <div className={styles.result_list}>
        
        {openAcc
          ? list.map((item: any, i) => (
            <motion.div variants={childVariants} initial='initial' animate='animate' exit={'exit'} key={`${item}-${i}`}>
              <Link
                href={`${
                  title === "treatments"
                    ? "/tratamiento"
                    : title === "zones"
                    ? "/zona"
                    : "/preocupacion"
                }/${item.slug.toLowerCase()}`}
                onClick={touch}
              >
                <p className={styles.result_list_item}>{item.title}</p>
              </Link>
              </motion.div>
              
            ))
          : null}
          
      </div>
    </motion.div>
  );
};
export default AccordeonItem;
